import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Elem from '../common/subNavElem';
import { navigate } from 'gatsby';

const Wrapper = styled.div`
    width: 100%;
    min-height: 75px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
`;

const Nav = () => {
    const [pathname, setPathname] = useState('/');

    useEffect(() => {
        setPathname(window.location.pathname);
    }, [setPathname]);

    return (
        <Wrapper>
            <Elem
                onClick={() => navigate('/verein')}
                active={pathname === '/verein'}
            >
                Über uns
            </Elem>
            <Elem
                onClick={() => navigate('/struktur')}
                active={pathname === '/struktur'}
            >
                Struktur
            </Elem>
            <Elem
                onClick={() => navigate('/leitbild')}
                active={pathname === '/leitbild'}
            >
                Leitbild
            </Elem>
            <Elem
                onClick={() => navigate('/mitglied-werden')}
                active={pathname === '/mitglied-werden'}
            >
                Mitglied werden
            </Elem>
            <Elem
                onClick={() => navigate('/satzung')}
                active={pathname === '/satzung'}
            >
                Satzung
            </Elem>
            <Elem
                onClick={() => navigate('/stellenanzeigen')}
                active={pathname === '/stellenanzeigen'}
            >
                Offene Stellen
            </Elem>
        </Wrapper>
    );
};

export default Nav;

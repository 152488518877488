import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    cursor: pointer;
    padding: 10px 20px;
    background: ${(props) => props.theme.colors.gray1};
    border-right: solid 1px ${(props) => props.theme.colors.gray2};
    border-bottom: solid 1px ${(props) => props.theme.colors.gray2};
    font-family: ${(props) => props.theme.fonts.heading};
    font-weight: 600;
    min-width: 100px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
        props.active &&
        `
        background: ${props.theme.colors.white};
        border-bottom: 0;
    `}
    &:hover {
        background: ${(props) => props.theme.colors.white};
    }
`;

const SubNavElem = ({ children, active, onClick }) => (
    <Wrapper active={active} onClick={onClick}>
        {children}
    </Wrapper>
);

export default SubNavElem;
